import { ReactElement, useEffect, useRef, useState } from 'react'
import {
  ActivitiesFilter,
  ActivitiesFilterProps,
} from '@/components/patterns/ActivityFilter/index'
import { ActivityList } from '@/components/patterns/ActivityList'
import { Activity } from '@/src/activities'
import {
  PaginatedActivities,
  PaginatedActivitiesProps,
} from '@/components/patterns/ActivityFilter/PaginatedActivities'
import { scrollIntoViewWithOffset } from '../../../utils/scrollIntoViewWithOffset'

export default function Home({
  activities,
}: {
  activities: Activity[]
}): ReactElement {
  const [filteredActivities, setFilteredActivities] = useState(activities)
  const [pagedActivities, setPagedActivities] = useState(filteredActivities)
  const [currentPage, setCurrentPage] = useState(1)
  const activitiesFilterProps: ActivitiesFilterProps = {
    activities,
    onFilter: setFilteredActivities,
  }
  const paginatedActivitiesProps: PaginatedActivitiesProps = {
    activities: filteredActivities,
    setActivities: setPagedActivities,
    currentPage,
    setCurrentPage,
  }
  const activitiesRef = useRef(null)
  const scrollToTopOfActivitiesList = () => {
    setTimeout(() => {
      scrollIntoViewWithOffset(activitiesRef.current, -200)
    }, 100)
  }

  return (
    <section id="activitats">
      <ActivitiesFilter {...activitiesFilterProps} />
      <div className="flex flex-col">
        <ActivityList
          activitiesListRef={activitiesRef}
          activities={pagedActivities}
        />
        <PaginatedActivities
          className="mt-8"
          afterPageChange={scrollToTopOfActivitiesList}
          {...paginatedActivitiesProps}
        />
      </div>
    </section>
  )
}
