import { ChevronForwardOutline } from 'react-ionicons'
import { SectionTitle } from '@/components/elements/SectionTitle'

import { Container } from '@/components/utils'
import { Image } from '@/components/elements/Image'
import { Button } from '@/components/elements/Button'
import { IonIcon } from '@/src/icons'

export interface CTASectionProps {
  title: string
  subtitle: string
  ctaUrl?: string
  ctaLabel?: string
}

export const CTASection: React.FC<CTASectionProps> = ({
  title,
  subtitle,
  ctaUrl,
  ctaLabel,
}) => {
  return (
    <section className="relative py-24">
      <div className="absolute z-0 inset-0 pointer-events-none">
        <Image
          src="/img/cta-background.jpeg"
          alt=""
          layout="fill"
          objectFit="cover"
          className="opacity-30"
        />
        <div className="absolute inset-0 bg-navy mix-blend-multiply" />
      </div>
      <Container className="relative z-10 text-white">
        <SectionTitle title={title} subtitle={subtitle} marginBottom={false} />
        {ctaLabel && ctaUrl && (
          <div className="flex justify-center mt-10">
            <Button
              href={ctaUrl}
              label={ctaLabel}
              icon={<IonIcon icon={ChevronForwardOutline} />}
            />
          </div>
        )}
      </Container>
    </section>
  )
}
