import cn from 'classnames'

export interface SectionTitleProps {
  title: string
  subtitle?: string
  marginBottom?: boolean
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  subtitle,
  marginBottom = true,
}) => (
  <div className={cn(marginBottom && `mb-20`)}>
    <h2 className="mb-4 px-4 text-center font-display text-4xl font-black leading-none lg:text-5xl">
      {title}
    </h2>
    {subtitle && <p className="text-center text-lg font-bold">{subtitle}</p>}
  </div>
)
