import { useEffect, useMemo } from 'react'
import { noop } from '../../utils/noop'

export interface PaginationData<T> {
  currentPage: number
  totalPageCount: number
  pageItems: T[]
  pageStart: number
  pageEnd: number
  nextPage: () => void
  previousPage: () => void
  hasNext: boolean
  hasPrevious: boolean
}

function paginate<T>(items: T[], pageSize: number, pageNumber: number): T[] {
  return items.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

export default function usePaginate<T>(
  inputItems: T[],
  pageSize: number,
  currentPage,
  setCurrentPage,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  afterPageChange = noop
) {
  const nextPage = () => {
    setCurrentPage(currentPage + 1)
    afterPageChange()
  }
  const previousPage = () => {
    setCurrentPage(currentPage - 1)
    afterPageChange()
  }
  const hasNextPage = (totalPageCount: number) => {
    return currentPage < totalPageCount
  }
  const hasPreviousPage = () => {
    return currentPage > 1
  }

  // restart pagination in case data from source is updated
  useEffect(() => {
    setCurrentPage(1)
  }, [inputItems])

  return useMemo((): PaginationData<T> => {
    const totalPageCount = Math.ceil(inputItems.length / pageSize)
    const pageItems = paginate(inputItems, pageSize, currentPage)
    const pageStart = (currentPage - 1) * pageSize + 1
    const pageEnd = currentPage * (pageSize + 1) - 1
    return {
      pageItems,
      currentPage,
      totalPageCount,
      nextPage,
      previousPage,
      hasNext: hasNextPage(totalPageCount),
      hasPrevious: hasPreviousPage(),
      pageStart,
      pageEnd: pageEnd < inputItems.length ? pageEnd : inputItems.length,
    }
  }, [currentPage, inputItems])
}
