import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { CloseOutline, ChevronDown } from 'react-ionicons'
import { useOnClickOutside } from '../../../lib/hooks'
import { IonIcon } from '@/src/icons'

export interface Option {
  label: string
  value: string
}

export interface DropDownProps {
  id: string
  label: string
  options: Array<Option>
  selectedOption: Option
  defaultLabel: string
  onSelected?: (option: Option) => void
}

export const DropDown: React.FC<DropDownProps> = ({
  id,
  label,
  options,
  selectedOption: initiallySelectedOption,
  defaultLabel,
  onSelected = () => null,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [selectedOption, setSelectedOption] = useState(initiallySelectedOption)
  const handleSelect = (option: Option) => {
    setExpanded(false)
    onSelected(option)
    setSelectedOption(option)
  }

  const optionsWithDefault = [{ label: defaultLabel, value: null }, ...options]
  const ref = useRef(null)
  useOnClickOutside(ref, () => setExpanded(false))

  useEffect(
    () => setSelectedOption(initiallySelectedOption),
    [initiallySelectedOption]
  )

  return (
    <div className="relative font-body" id={id} ref={ref}>
      <button
        type="button"
        className="flex items-center justify-center py-4 w-44 font-bold bg-white"
        onClick={() => setExpanded(!expanded)}
        aria-haspopup="listbox"
        aria-expanded={expanded}
        aria-label={`Seleccioneu ${label}, the current selection is ${selectedOption.label}`}
      >
        <div className="flex">
          <span
            className="uppercase"
            role="region"
            aria-live="polite"
            id="selectedOption"
            aria-label={`The new current selection is ${selectedOption.label}`}
          >
            {selectedOption.value ? selectedOption.label : label}
          </span>
        </div>
        <span className="flex pl-4 text-orange" aria-hidden>
          {expanded ? (
            <IonIcon icon={CloseOutline} className="w-6" aria-hidden />
          ) : (
            <IonIcon icon={ChevronDown} className="w-6" aria-hidden />
          )}
        </span>
      </button>
      <AnimatePresence exitBeforeEnter>
        {expanded && (
          <motion.ul
            className="absolute z-10 top-14 mt-2 px-6 py-5 w-40 bg-white rounded-lg shadow-600"
            aria-label={`Options for ${label}`}
            role="listbox"
            initial={{ opacity: 0, y: '10%' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, y: '10%' }}
            aria-controls="selectedOption"
          >
            {optionsWithDefault.map((option) => (
              <li
                className="last:mb-0 mb-7 w-full hover:text-orange focus:text-orange font-bold cursor-pointer"
                key={option.value}
                role="option"
                aria-selected={selectedOption.value === option.value}
                onClick={() => handleSelect(option)}
                onKeyPress={() => handleSelect(option)}
                tabIndex={0}
              >
                {option.label}
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  )
}
