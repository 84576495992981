import { GetStaticPropsResult } from 'next'
import React, { ReactElement } from 'react'
import { Activity, getActiveActivities } from 'src/activities'
import { PageMeta } from 'src/pages'
import { Layout } from '@/components/Layout'
import HomeActivities from '@/components/patterns/ActivityFilter/Home'
import { Hero, HeroProps } from '@/components/patterns/Hero'
import homeData from '@/content/home.json'
import { HowItWorks, HowItWorksProps } from '@/components/patterns/HowItWorks'
import {
  AboutSection,
  AboutSectionProps,
} from '@/components/patterns/AboutSection'
import { CTASection, CTASectionProps } from '@/components/patterns/CtaSection'
import { Promoters, PromotersProps } from '@/components/patterns/Promoters'
import ClientOnly from '@/components/ClientOnly'
import {
  NewsletterSection,
  NewsletterSectionProps,
} from '@/components/patterns/NewsletterSection'

interface HomeProps {
  activities: Activity[]
  // eslint-disable-next-line react/no-unused-prop-types
  meta: PageMeta
  hero: HeroProps
  howItWorks: HowItWorksProps
  about: AboutSectionProps
  cta: CTASectionProps
  newsletter: NewsletterSectionProps
  promoters: PromotersProps
}

export async function getStaticProps(): Promise<
  GetStaticPropsResult<HomeProps>
> {
  const activities = await getActiveActivities()

  return {
    props: {
      activities,
      ...homeData,
      newsletter: homeData.newsletter,
    },
  }
}

export default function Home({
  activities,
  meta,
  howItWorks,
  hero,
  about,
  cta,
  newsletter,
  promoters,
}: HomeProps): ReactElement {
  return (
    <Layout meta={meta}>
      <Hero {...hero} />
      <ClientOnly>
        <HomeActivities activities={activities} />
      </ClientOnly>
      <HowItWorks {...howItWorks} />
      <AboutSection {...about} />
      <CTASection {...cta} />
      <NewsletterSection {...newsletter} />
      <Promoters {...promoters} />
    </Layout>
  )
}
