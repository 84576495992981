import React from 'react'
import { Link } from '@/components/types'
import { Button } from '@/components/elements/Button'
import { Container } from '@/components/utils'
import { Image } from '@/components/elements/Image'

export interface HeroProps {
  title: string
  subtitle: string
  picture: string
  cta: Link
}

export const Hero: React.FC<HeroProps> = ({
  title,
  subtitle,
  picture,
  cta: { href, label },
}) => (
  <>
    <div className="relative">
      <Image
        className="absolute z-10 inset-0 pointer-events-none"
        src={picture}
        layout="fill"
        objectPosition="center"
        objectFit="cover"
        priority
        quality={55}
        alt=""
      />
      <div className="absolute z-20 inset-0 bg-black opacity-30" />
      <Container className="relative z-20">
        <div className="py-28 w-full max-w-xl font-body leading-none lg:max-w-4xl">
          <h1 className="text-white font-display text-5xl font-black lg:text-7xl">
            {title}
          </h1>
          <p className="mt-10 text-white text-lg font-bold leading-7 lg:text-xl">
            {subtitle}
          </p>
          <div className="mt-10">
            <Button variant="default" href={href} label={label} />
          </div>
        </div>
      </Container>
    </div>
  </>
)
