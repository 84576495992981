import { Activity } from 'src/activities'
import { GlassesOutline } from 'react-ionicons'
import {
  AnimatePresence,
  AnimateSharedLayout,
  motion,
  Variants,
} from 'framer-motion'
import { ActivityCard } from '@/components/patterns/ActivityCard'
import { Container } from '@/components/utils'
import { IonIcon } from '@/src/icons'

interface ActivityListProps {
  activities: Activity[]
  activitiesListRef: any
}

const itemVariants: Variants = {
  enter: (index) => ({
    opacity: 1,
    scale: 1,
    transition: { delay: index * 0.05 + 0.5 },
  }),
  exit: (index) => ({
    opacity: 0,
    scale: 0.9,
    transition: { delay: index * 0.05 },
  }),
}

export function ActivityList({
  activities,
  activitiesListRef,
}: ActivityListProps): JSX.Element {
  return (
    <>
      <Container className="w-full">
        {activities.length > 0 ? (
          <div
            ref={activitiesListRef}
            className="grid gap-x-4 gap-y-8 grid-cols-1 mb-1 auto-rows-fr sm:grid-cols-2 lg:grid-cols-3"
          >
            <AnimatePresence initial={false}>
              {activities.map((activity, index) => (
                <motion.div
                  initial="exit"
                  animate="enter"
                  exit="exit"
                  key={`${activity.id}`}
                  variants={itemVariants}
                  custom={index}
                >
                  <ActivityCard activity={activity} />
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        ) : (
          <div className="flex flex-col items-center py-2 text-xl">
            <IonIcon className="mr-2 w-32" icon={GlassesOutline} />
            <h3 className="text-center text-6xl font-black leading-none">
              Sense resultats
            </h3>
            <p className="mt-8 max-w-xl text-center font-body">
              No hi ha cap coincidència amb els filtres aplicats. Prova fer una
              altra combinació o contacta’ns per si et podem ajudar
            </p>
          </div>
        )}
      </Container>
    </>
  )
}
