import { motion, Variants } from 'framer-motion'

import {
  GlassesOutline,
  CheckmarkDoneSharp,
  PeopleOutline,
  ChatbubbleOutline,
  TicketOutline,
  ChatbubblesOutline,
} from 'react-ionicons'

import { EyeOutlineIcon } from '@/components/elements/VariousIcons'

import { SectionTitle } from '@/components/elements/SectionTitle'

import { IonIcon } from '@/src/icons'
import { Container } from '@/components/utils'
import { useAnimateOnEnterView } from '@/src/hooks/useAnimateOnEnterView'
import { Divider } from '@/components/elements/Divider'

export const icons = {
  glasses: GlassesOutline,
  checkmark: CheckmarkDoneSharp,
  people: PeopleOutline,
  chat: ChatbubbleOutline,
  ticket: TicketOutline,
  chat2: ChatbubblesOutline,
  eye: EyeOutlineIcon,
}

type ValidIcon = keyof typeof icons

interface Element {
  title: string
  icon: ValidIcon | string
  text: string
}

interface Section {
  title: string
  elements: Array<Element>
}

export interface HowItWorksProps {
  title: string
  subtitle: string
  sections: Array<Section>
}

const Element: React.FC<Element & { number: number }> = ({
  number,
  title,
  icon,
  text,
}) => {
  return (
    <div className="pt-6">
      <div className="flex items-center mb-6 pb-6 h-14 border-b-2 border-navy">
        <span className="flex-1 text-orange font-display text-6xl font-black leading-none">
          {number}
        </span>
        {icon === 'eye' ? (
          <EyeOutlineIcon className="w-16 text-navy" strokeColor="#4f527a" />
        ) : (
          <IonIcon className="w-16 text-navy" icon={icons[icon]} />
        )}
      </div>
      <h4 className="mb-2 text-orange font-display text-2xl font-black leading-snug">
        {title}
      </h4>
      <p>{text}</p>
    </div>
  )
}

const Section: React.FC<Section> = ({ title, elements }) => {
  const { ref, controls } = useAnimateOnEnterView<HTMLDivElement>('visible')

  const variants: Variants = {
    visible: {
      transition: {
        staggerChildren: 0.3,
      },
    },
  }

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: 'tween',
      },
    },
  }

  const elementVariants: Variants = {
    hidden: {
      y: '10%',
      opacity: 0,
    },
    visible: {
      y: '0',
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 0.6,
      },
    },
  }

  return (
    <motion.div
      className="mx-auto max-w-5xl"
      variants={variants}
      ref={ref}
      initial="hidden"
      animate={controls}
    >
      <motion.h3
        className="mb-16 text-center font-display text-2xl font-black lg:text-3xl"
        variants={titleVariants}
      >
        {title}
      </motion.h3>
      <div className="grid gap-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
        {elements.map((element, index) => (
          <motion.div key={element.title} variants={elementVariants}>
            <Element {...element} number={index + 1} />
          </motion.div>
        ))}
      </div>
    </motion.div>
  )
}

export const HowItWorks: React.FC<HowItWorksProps> = ({
  title,
  subtitle,
  sections,
}) => {
  return (
    <section className="py-24">
      <Container>
        <SectionTitle title={title} subtitle={subtitle} />
        {sections.map((section, index) => (
          <div key={section.title}>
            {index > 0 && <Divider className="my-20" />}
            <Section {...section} />
          </div>
        ))}
      </Container>
    </section>
  )
}
