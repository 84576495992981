import { useAnimation } from 'framer-motion'
import {
  AnimationControls,
  ControlsAnimationDefinition,
} from 'framer-motion/types/animation/types'
import { useEffect, useState } from 'react'
import { useInView } from 'react-hook-inview'

export function useAnimateOnEnterView<T>(
  definition: ControlsAnimationDefinition,
  options?: { threshold: number; rootMargin: string }
): {
  ref: (node: T) => void
  controls: AnimationControls
  inView: boolean
  hasAnimated: boolean
} {
  const controls = useAnimation()
  const [hasAnimated, setHasAnimated] = useState(false)

  const [ref, inView] = useInView({
    threshold: 0.2,
    unobserveOnEnter: true,
    ...options,
  })

  useEffect(() => {
    if (inView && !hasAnimated) {
      controls.start(definition).then(() => setHasAnimated(true))
    }

    return () => controls.stop()
  }, [inView, controls, definition, hasAnimated])

  return {
    controls,
    ref: ref as unknown as (node: T) => void,
    inView,
    hasAnimated,
  }
}
