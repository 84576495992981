import {
  ExtensionPuzzleOutline,
  HeartOutline,
  TextOutline,
} from 'react-ionicons'

import { DiscOutlineIcon } from '@/components/elements/VariousIcons'

import { IonIcon } from '@/src/icons'

import { SectionTitle } from '@/components/elements/SectionTitle'

import { Container } from '@/components/utils'

export const icons = {
  disc: DiscOutlineIcon,
  heart: HeartOutline,
  puzzle: ExtensionPuzzleOutline,
  text: TextOutline,
}

type ValidIcon = keyof typeof icons

interface Item {
  title: string
  description: string
  icon: ValidIcon | string
}

export interface AboutSectionProps {
  title: string
  subtitle: string
  items: Array<Item>
}

export const Item: React.FC<Item> = ({ title, description, icon }) => (
  <li className="flex">
    <span className="flex justify-start w-16">
      {icon === 'disc' ? (
        <DiscOutlineIcon
          className="w-12 h-12 text-omnium"
          strokeColor="#fc4c02"
        />
      ) : (
        <IonIcon className="w-12 h-12 text-omnium" icon={icons[icon]} />
      )}
    </span>
    <span className="flex-1">
      <h3 className="font-display text-2xl font-black">{title}</h3>
      <p>{description}</p>
    </span>
  </li>
)

export const AboutSection: React.FC<AboutSectionProps> = ({
  title,
  subtitle,
  items,
}) => {
  return (
    <section className="py-24 bg-beige" id="qui-som">
      <Container>
        <SectionTitle title={title} subtitle={subtitle} />
        <ul className="grid gap-14 md:grid-cols-2">
          {items.map((item) => (
            <Item key={item.title} {...item} />
          ))}
        </ul>
      </Container>
    </section>
  )
}
