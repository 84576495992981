import { FC, useEffect, useState } from 'react'
import { uniq } from 'lodash-es'
import { Activity } from '@/src/activities'
import { DropDown, DropDownProps } from '@/components/elements/Dropdown'
import { Container } from '@/components/utils'

export interface ActivitiesFilterProps {
  activities: Activity[]
  onFilter: (activities: Activity[]) => void
}

export const ActivitiesFilter: FC<ActivitiesFilterProps> = ({
  activities,
  onFilter,
}) => {
  const [selectedProvince, setSelectedProvince] = useState({
    label: 'Província',
    value: '',
  })
  const [selectedCity, setSelectedCity] = useState({
    label: 'Localitat',
    value: '',
  })
  const [selectedCategory, setSelectedCategory] = useState({
    label: 'Disciplina',
    value: '',
  })

  const provinces = uniq(activities.map(({ province }) => province)).sort()
  let cities = uniq(activities.map(({ city }) => city)).sort(
    Intl.Collator('ca').compare
  )
  if (selectedProvince.value) {
    cities = uniq(
      activities
        .filter(({ province }) => province === selectedProvince.value)
        .map(({ city }) => city)
    ).sort(Intl.Collator('ca').compare)
  }
  const categories = uniq(activities.map(({ category }) => category)).sort(
    Intl.Collator('ca').compare
  )

  const provinceFilter: DropDownProps = {
    id: 'province',
    label: 'Província',
    defaultLabel: 'Totes',
    options: provinces.map((province) => ({
      label: province,
      value: province,
    })),
    selectedOption: selectedProvince,
    onSelected: setSelectedProvince,
  }

  const cityFilter = {
    id: 'province',
    label: 'Localitat',
    defaultLabel: 'Totes',
    options: cities.map((city) => ({
      label: city,
      value: city,
    })),
    onSelected: setSelectedCity,
    selectedOption: selectedCity,
  }

  const categoriesFilter = {
    id: 'category',
    label: 'Categories',
    defaultLabel: 'Totes',
    options: categories.map((category) => ({
      label: category,
      value: category,
    })),
    onSelected: setSelectedCategory,
    selectedOption: selectedCategory,
  }

  useEffect(() => {
    let newActivities = [...activities]
    if (selectedCity.value) {
      newActivities = newActivities.filter(
        (activity) =>
          activity.city.toLocaleLowerCase() ===
          selectedCity.value.toLocaleLowerCase()
      )
    }
    if (selectedProvince.value) {
      newActivities = newActivities.filter(
        (activity) =>
          activity.province.toLocaleLowerCase() ===
          selectedProvince.value.toLocaleLowerCase()
      )
    }
    if (selectedCategory.value) {
      newActivities = newActivities.filter(
        (activity) =>
          activity.category.toLocaleLowerCase() ===
          selectedCategory.value.toLocaleLowerCase()
      )
    }
    onFilter(newActivities)
  }, [selectedProvince, selectedCity, selectedCategory, activities, onFilter])

  useEffect(() => {
    if (!selectedProvince.value) return

    const citiesInProvince = activities
      .filter(({ province }) => province === selectedProvince.value)
      .map(({ city }) => city)

    if (!citiesInProvince.includes(selectedCity.value)) {
      setSelectedCity({
        label: '',
        value: null,
      })
    }
  }, [activities, selectedCity.value, selectedProvince])

  useEffect(() => {
    if (!selectedCity.value) return

    const { province } = activities.find(
      ({ city }) => city === selectedCity.value
    )
    setSelectedProvince({
      label: province,
      value: province,
    })
  }, [activities, selectedCity])

  const dropDownFilters = [provinceFilter, cityFilter, categoriesFilter]
  return (
    <Container className="pt-22 bg-white">
      <div
        className="flex flex-col justify-center pb-12 sm:px-4 lg:flex-row lg:px-6 xl:px-24"
        id="activitats-filter"
      >
        <div className="grid gap-x-4 gap-y-8 grid-cols-1 mb-8 auto-rows-fr sm:grid-cols-3 lg:mb-0">
          {dropDownFilters.map((item) => (
            <div
              key={item.label}
              className="flex justify-center sm:justify-start"
            >
              <DropDown {...item} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}
