export interface VariousIconProps {
  className?: string
  strokeColor?: string
}

export const DiscOutlineIcon: React.FC<VariousIconProps> = ({
  className = '',
  strokeColor = '#111827',
}) => (
  <div className={className}>
    <svg
      width="100%"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 43.5C34.7696 43.5 43.5 34.7696 43.5 24C43.5 13.2304 34.7696 4.5 24 4.5C13.2304 4.5 4.5 13.2304 4.5 24C4.5 34.7696 13.2304 43.5 24 43.5Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M24 33C28.9706 33 33 28.9706 33 24C33 19.0294 28.9706 15 24 15C19.0294 15 15 19.0294 15 24C15 28.9706 19.0294 33 24 33Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M26.5 24C26.5 25.3807 25.3807 26.5 24 26.5C22.6193 26.5 21.5 25.3807 21.5 24C21.5 22.6193 22.6193 21.5 24 21.5C25.3807 21.5 26.5 22.6193 26.5 24Z"
        fill={strokeColor}
        stroke={strokeColor}
      />
    </svg>
  </div>
)
export const EyeOutlineIcon: React.FC<VariousIconProps> = ({
  className = '',
  strokeColor = '#111827',
}) => (
  <div className={className}>
    <svg
      width="100%"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.78 16.2251C28.6019 16.2251 16.1097 23.2735 6.27534 37.3704C5.99463 37.7772 5.84081 38.2581 5.8333 38.7523C5.82579 39.2465 5.96494 39.7318 6.23316 40.147C13.7894 51.9751 26.1144 61.2251 40.78 61.2251C55.2863 61.2251 67.8644 51.947 75.4347 40.0923C75.6967 39.6853 75.836 39.2115 75.836 38.7274C75.836 38.2434 75.6967 37.7696 75.4347 37.3626C67.8472 25.6438 55.1769 16.2251 40.78 16.2251Z"
        stroke={strokeColor}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 52.5C46.9036 52.5 52.5 46.9036 52.5 40C52.5 33.0964 46.9036 27.5 40 27.5C33.0964 27.5 27.5 33.0964 27.5 40C27.5 46.9036 33.0964 52.5 40 52.5Z"
        stroke={strokeColor}
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
)
