import { Container } from '@/components/utils'
import { Divider } from '@/components/elements/Divider'

interface Promoter {
  name: string
  image: string
  url: string
}

interface Group {
  title: string
  promoters: Promoter[]
}

export interface PromotersProps {
  groups: Group[]
}

export const Promoters: React.FC<PromotersProps> = ({ groups }) => {
  return (
    <>
      <section className="pt-10">
        <Container>
          <div className="mb-20 lg:flex lg:flex-wrap lg:justify-center lg:space-x-16">
            {groups.map((group) => (
              <div key={group.title}>
                <div className="my-2 text-center lg:mb-0 lg:text-left">
                  {group.title}
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center -mx-8 md:flex-row">
                  {group.promoters.map(({ name, image, url }) => (
                    <a
                      key={name}
                      className="relative mt-4 mx-8"
                      href={url}
                      target="_blank"
                      title={name}
                      rel="noreferrer"
                    >
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        src={image}
                        alt={name}
                        className="max-w-full h-12 object-contain"
                      />
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Divider />
        </Container>
      </section>
    </>
  )
}
