import classNames from 'classnames'
import React, { FC, useEffect } from 'react'
import { AnimateSharedLayout, motion, Variants } from 'framer-motion'
import { Container } from '@/components/utils'
import { Button } from '@/components/elements/Button'
import usePaginate from '@/src/hooks/usePaginate'
import { Activity } from '@/src/activities'

const variants: Variants = {
  enter: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.05,
    },
  },
  exit: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.05,
    },
  },
}
export interface PaginatedActivitiesProps {
  activities: Activity[]
  setActivities: (activities: Activity[]) => any
  currentPage: number
  setCurrentPage: (page: number) => void
  afterPageChange?: () => void
  className?: string
}
export const PaginatedActivities: FC<PaginatedActivitiesProps> = ({
  activities,
  setActivities,
  currentPage,
  setCurrentPage,
  afterPageChange,
  className = '',
}) => {
  const {
    pageItems,
    nextPage,
    previousPage,
    hasNext,
    hasPrevious,
    pageStart,
    pageEnd,
  } = usePaginate<Activity>(
    activities,
    6,
    currentPage,
    setCurrentPage,
    afterPageChange
  )

  useEffect(() => {
    setActivities(pageItems)
  }, [pageItems, setActivities])

  return (
    <Container className="w-full">
      <AnimateSharedLayout>
        {pageItems.length > 0 && (
          <motion.div
            layout
            initial="exit"
            animate="enter"
            exit="exit"
            variants={variants}
            className={classNames(
              className,
              'flex flex-col items-center justify-between sm:flex-row '
            )}
          >
            <div className="mb-8 sm:mb-0">
              <span>
                Mostrant del <strong> {pageStart} </strong> al
                <strong> {pageEnd}</strong> de
                <strong> {activities.length} </strong>
                resultats
              </span>
            </div>
            <div className="grid gap-x-4 grid-flow-col">
              <Button
                disabled={!hasPrevious}
                variant="outlined"
                label="Previ"
                onClick={previousPage}
              />
              <Button
                disabled={!hasNext}
                variant="outlined"
                label="Següent"
                onClick={nextPage}
              />
            </div>
          </motion.div>
        )}
      </AnimateSharedLayout>
    </Container>
  )
}
